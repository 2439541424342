<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">抽奖设置</h3>
    </div>
    <div class="box">
      <div class="titles">
        <el-button size="small" type="primary" @click="addInfo"
          >添加抽奖</el-button>
          <!-- <el-button size="small" type="primary" @click="dialogLiveCate = true"
          >删除</el-button> -->
      </div>
      <div class="tables">
        <!-- 表格内容 -->
         <el-table
            ref="multipleTable"
            :data="list"
            border
            tooltip-effect="dark"
            style="width: 100%">
            <el-table-column label="奖项id" prop="id" align="center">
            </el-table-column>
            <el-table-column label="首页图片">
                <template slot-scope="scope">
                     <el-image style="width: 100px; height: 100px" :src="imgurl + scope.row.banner" />
                </template>
            </el-table-column>
            <el-table-column label="头部背景图片">
                <template slot-scope="scope">
                     <el-image style="width: 100px; height: 100px" :src="imgurl + scope.row.title_pic" />
                </template>
            </el-table-column>
            <el-table-column label="获奖内容图片">
                <template slot-scope="scope">
                     <el-image style="width: 100px; height: 100px" :src="imgurl + scope.row.content"/>
                </template>
            </el-table-column>
            <el-table-column label="添加时间" prop="addtime">
            </el-table-column>
            <el-table-column label="是否展示">
                  <template slot-scope="scope">
                    <el-switch
                    v-model="scope.row.is_open" 
                    active-color="#13ce66"
                    inactive-color="#999"
                    :active-value="1"
                    :inactive-value="2"
                    @change="luckdrawsChanges(scope.row.id)"
                    />
                    <!-- <el-switch
                    v-model="scope.row.is_open == 1"
                    class="switchClass"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="handleSwitchChange($event)">
                  </el-switch> -->
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
                 <template slot-scope="scope">
                    <el-button @click="editArticle(scope.row.id)" type="primary" size="small">编辑</el-button>
                    <!-- <el-button v-if="scope.row.status == 2" @click="showHiddenComment(scope.row.id, 1)" type="primary" size="small">显示文章</el-button>
                    <el-button v-if="scope.row.status == 1" @click="showHiddenComment(scope.row.id, 2)" type="warning" size="small">隐藏文章</el-button> -->
                    <!-- <el-button @click="winningDetail(scope.row.id)" type="primary" size="small">查看中奖人员</el-button> -->
                    <el-button @click="listDelete(scope.row.id)" type="danger" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页控制 -->
        <el-pagination
          style="margin-top: 20px"
          @current-change="cateListCrtChg"
          :current-page="listForm.page"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 添加编辑类型 -->
    <el-dialog title="直播分类" width="60%" :visible.sync="dialogLiveCate">
      <el-form :rules="rules" :model="winningInfoForm" ref="addlivecate">
        <el-form-item label="首页图片：" prop="banner">
            <el-input v-model="winningInfoForm.banner" :disabled="true" placeholder="缩略图路径"></el-input>
            <span class="uphint">请上传首页图片, 注意尺寸大小740*165</span>
            <up-img v-if="winningInfoForm.banner == ''" @addimg="upMinimg" :type="'shop'"></up-img>
            <div v-if="winningInfoForm.banner != ''" class="image">
              <img :src="imgurl+winningInfoForm.banner" alt="" />
              <span class="shou" @click="delMinimg(1)">×</span>
            </div>
				</el-form-item>
        <el-form-item label="详情背景图片：" prop="title_pic">
            <el-input v-model="winningInfoForm.title_pic" :disabled="true" placeholder="缩略图路径"></el-input>
            <span class="uphint">请上传详情背景图, 注意尺寸大小750*470</span>
            <up-img v-if="winningInfoForm.title_pic == ''" @addimg="upMinimg1" :type="'shop'"></up-img>
            <div v-if="winningInfoForm.title_pic != ''" class="image">
              <img :src="imgurl+winningInfoForm.title_pic" alt="" />
              <span class="shou" @click="delMinimg(2)">×</span>
            </div>
				</el-form-item>
        <el-form-item label="中奖人员名单图片：" prop="content">
            <el-input v-model="winningInfoForm.content" :disabled="true" placeholder="缩略图路径"></el-input>
            <span class="uphint">请上传中奖人员名单图片, 注意尺寸大小</span>
            <up-img v-if="winningInfoForm.content == ''" @addimg="upMinimg2" :type="'shop'"></up-img>
            <div v-if="winningInfoForm.content != ''" class="image">
              <img :src="imgurl+winningInfoForm.content" alt="" />
              <span class="shou" @click="delMinimg(3)">×</span>
            </div>
				</el-form-item>
        <el-form-item label="状态：">
            <el-radio v-model="winningInfoForm.is_open" :label="1">显示</el-radio>
            <el-radio v-model="winningInfoForm.is_open" :label="2">不显示</el-radio>
        </el-form-item>
        <el-form-item label="提示内容：" prop="tip">
            <el-input v-model="winningInfoForm.tip"></el-input>
        </el-form-item>
        <el-form-item label="备注内容：" prop="remarks">
            <el-input v-model="winningInfoForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <div class="top">
        <span
          class="addshop shou"
          style="background-color: #369bde; margin: 0 10px"
          @click="excelVisible = true"
          >导入中奖人列表 <i class="el-icon-folder-add"></i
        ></span>
      </div>
      <div class="person">{{winningInfoForm.file_roster}}</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clone">取 消</el-button>
        <el-button type="primary" @click="addLiveCate">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 导入门店 -->
    <el-dialog title="文件上传" :visible.sync="excelVisible" width="40%" center>
      <!--  :show-file-list="false" 是否显示上传文件的list -->
      <!-- drag  是否可拖拽 -->
      <!--  multiple 是否上传多文件 -->
      <!--   :action="upUrl" 上传地址 -->
      <el-upload
        style="text-align: center"
        :show-file-list="false"
        :before-upload="handleBeforeUpload"
        :http-request="uploadadSectionFile"
        drag
        action=""
        multiple
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">仅限xls和xlsx文件</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button
          style="margin-right: 20px"
          size="mini"
          @click="excelVisible = false"
          >取 消</el-button
        >
        <el-button size="mini" type="success" @click="downloadBTN"
          >下载模板</el-button
        >
        <a
          class="downloadBTN"
          target="_blank"
          href="https://adminbayj.qiannianjituan.com/uploads/zjzs_demo.xlsx"
          style="display: none"
        ></a>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import axios from "axios";
axios.interceptors.request.use((response) => {
  let token = window.localStorage.getItem("admin_token") || "";
  response.headers.AdminToken = token;
  return response;
});
import { showLoading, hideLoading } from "@/utils/loading.js";
import { filenameFormat } from "@/utils/index";
import {
  luckdrawsList,
  luckdrawsSave,
  luckdrawsDetails,
  luckdrawsDelete,
  luckdrawsImport,
  luckdrawsChange
} from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import UpImg from "@/components/upImg.vue";
// import Justify from 'wangeditor/dist/menus/justify';
export default {
  components: {
    UpImg
  },
  name: "drawSet",
  data() {
    return {
      listForm: {
        name: "",
        page: 1,
        limit: 10,
      },
      value: true,
      total: 1,
      list: [],
      imgurl: '',
      //  添加编辑分类
      dialogLiveCate: false,
      winningInfoForm: {
        id: "", 
        banner: "", //首页展示图片
        file_roster: "", //上传文件
        title_pic: "", //中奖页面背景图
        content: "",  //中奖人员图片
        is_open: 2, //是否展示
        roster: "", //	人员门店
        tip: "", //提示内容
        remarks: "", //备注信息
      },
      excelVisible: false,
      rules: {
        banner: [{ required: true, message: "请上传首页图片", trigger: "blur" }],
        title_pic: [{ required: true, message: "请上传详情背景图片", trigger: "blur" }],
        content: [{ required: true, message: "请上传中奖人员名单图片", trigger: "blur" }],
        tip: [{ required: true, message: "请输入提示内容", trigger: "blur" }],
        remarks: [{ required: true, message: "请输入备注信息", trigger: "blur" }],
        file_roster: [{ required: true, message: "请上传中奖人员信息", trigger: "blur" }]
      },
    };
  },
  // watch: {
  //   dialogLiveCate: function (newData, oldData) {
  //     if (!newData) {
  //       delete this.winningInfoForm.id;
  //       this.winningInfoForm.name = "";
  //     }
  //   },
  // },
  methods: {
    // 删除中奖信息列表
    listDelete(id) {
      this.$confirm("此为永久删除?, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await luckdrawsDelete({ id: id });
          if (data.code != 200) return this.$message.error(data.data);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getlist()
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 是否展示
    async luckdrawsChanges(id){
      const { data } = await luckdrawsChange({ id: id });
      if (data.code != 200) return this.$message.error(data.data);
      this.$message({
        type: "success",
        message: "操作成功!",
      });
      this.getlist()
      // console.log(id)
    },
    // 编辑
    async editArticle(id) {
        const { data } = await luckdrawsDetails({ id: id });
        console.log(data)
        this.winningInfoForm.id = data.data.id, 
        this.winningInfoForm.banner = data.data.banner,
        this.winningInfoForm.file_roster = data.data.roster, 
        this.winningInfoForm.title_pic = data.data.title_pic, 
        this.winningInfoForm.content = data.data.content,
        this.winningInfoForm.is_open = Number(data.data.is_open),
        this.winningInfoForm.tip = data.data.tip,
        this.winningInfoForm.remarks = data.data.remarks,
        this.dialogLiveCate = true;
    },
    // 下载模板
    downloadBTN() {
      showLoading();
      let a = document.querySelector(".downloadBTN");
      a.download = this.fileName();
      console.log(a);
      a.click();
      hideLoading();
      this.exportShow = false;
    },
    // 中奖人员名单
    fileName() {
      let date = new Date();
      let time = this.$timeTo.time3(this.$timeTo.time4(date));
      return `中奖人员名单-${time}`;
    },
    // 查看中奖人员名单
    // winningDetail(id){
    //   this.$router.push({ path: "/live/winningPersonnel", query: { id } });
    // },
    // 删除图片
			delMinimg(index) {
        if(index == 1){
				  this.winningInfoForm.banner = "";
        }else if(index == 2){
          this.winningInfoForm.title_pic = "";
        }else{
          this.winningInfoForm.content = "";
        }
			},
      // 上传图片
			upMinimg(e) {
				this.winningInfoForm.banner = e;
			},
      upMinimg1(e) {
				this.winningInfoForm.title_pic = e;
			},
      upMinimg2(e) {
				this.winningInfoForm.content = e;
			},
    // 添加中奖信息详情
    addLiveCate() {
      this.$refs["addlivecate"].validate(async (valid) => {
        if (valid) {
          this.winningInfoForm.file_roster = JSON.stringify(this.winningInfoForm.file_roster)
          const { data } = await luckdrawsSave(this.winningInfoForm);
          if (data.code != 200) return this.$message.error(data.data);
          this.$message.success(data.data);
          this.dialogLiveCate = false;
          this.getlist();
          this.resetData()
        } else {
          this.$message.warning("请检查填写内容!");
          return false;
        }
      });
    },
  // 上传文件
    async uploadadSectionFile(params) {
      console.log('55555====', params)
      let form = new FormData();
      form.append("file", params.file);
      const { data } = await luckdrawsImport(form);
      if (data.code != 200) return this.$message.error(data.data);
      this.$message.success("上传成功");
      this.winningInfoForm.file_roster = data.data
      this.excelVisible = false;
      console.log('6666')
    },

    //上传之前校验的方法
    handleBeforeUpload(file) {
      // 校验
      let legalName = ["xlsx", "xls"];
      // 拿到后缀名
      let name = file.name.substring(
        file.name.lastIndexOf(".") + 1,
        file.name.length
      );
      if (legalName.includes(name)) {
        console.log(legalName.includes(name));
      } else {
        this.$message.info("文件格式不对，仅限xls和xlsx");
        return false;
      }
    },

    // 获取直播分类列表
    async getlist() {
        const { data } = await luckdrawsList(this.listForm);
        console.log(data)
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.list.data;
        this.total = data.data.list.total;
    },
    // 打开弹窗
    addInfo(){
      this.resetData()
      this.dialogLiveCate = true
    },
    // 关闭弹窗
    clone(){
      this.dialogLiveCate = false
      this.resetData()
    },
    // 重置from表单
    resetData(){
      this.winningInfoForm.id = '' 
      this.winningInfoForm.banner = '' 
      this.winningInfoForm.file_roster = '' 
      this.winningInfoForm.roster = '' 
      this.winningInfoForm.title_pic = '' 
      this.winningInfoForm.content = '' 
      this.winningInfoForm.is_open = '' 
      this.winningInfoForm.tip = '' 
      this.winningInfoForm.remarks = '' 
    },
    // 分页
    cateListCrtChg(e) {
      this.listForm.page = e;
      this.getlist();
    },

  },
  created() {
    this.getlist();
    getSystemsettings.then((res) => {
      console.log(res)
      this.imgurl = res;
    });
  },
};
</script>

<style scoped lang='less'>
.titles {
  background-color: rgb(241, 243, 244);
  padding: 10px;
  p {
    font-size: 14px;
    margin: 10px 0;
    span {
      margin-right: 5px;
    }
    input {
      height: 30px;
    }
  }
}
.tables {
  margin: 20px;
}
.el-dialog {
  .el-input {
    width: 50%;
  }
}
.xiugai {
    color: #999;
    border-bottom:  1px solid #999;
    margin-left: 12px;
}
.el-form-item {
			.imagelist {
				display: flex;
			}

			.el-radio {
				margin-left: 30px;

				&:last-child {
					margin-left: 0px;
				}
			}

			.image {
				font-size: 12px;
				width: 130px;
				height: 150px;
				color: #aaa;
				border: 1px solid #eeeeee;
				position: relative;
				top: -10px;
				margin: 10px 10px 0 0;

				img {
					width: 130px;
					height: 150px;
				}

				span {
					position: absolute;
					top: 0;
					right: 0;
					width: 20px;
					text-align: center;
					line-height: 20px;
					font-size: 12px;
					height: 20px;
					background-color: #ffd1d1;
					color: #fff3f3;
					z-index: 9;

					&:hover {
						background-color: #ff4544;
						color: #ffffff;
					}
				}
			}

			.upimg {
				border: 1px solid #777;
				font-size: 20px;
				padding: 1px 12px;
				margin: 1px 0 0 20px;
				color: #777;

				&:last-child {
					margin: 10px;
				}
			}

			position: relative;

			.dingwei {
				position: absolute;
				bottom: -26px;
				font-size: 12px;
				color: #777;
			}

			.el-input {
				.append {
					padding: 0;
					display: flex;
					justify-content: space-between;

					i {
						display: block;

						&:nth-child(2) {
							background-color: pink;
						}
					}
				}

				width: 500px;

				/deep/.el-input__inner {
					height: 34px;
					color: #333;
				}
			}

			.el-dropdown {
				span {
					border: 1px solid #888;
					padding: 6px 10px;
					margin-left: 30px;
				}
			}
		}
    .addshop {
      background-color: #37d6b7;
      padding: 5px 10px;
      color: #f1f1f1;
      border-radius: 3px;
      transition: font-size 0.2s;
    }
    .person{ 
      width: 100%;
      padding: 20px;
      word-wrap:  break-word;   
      word-break:  normal;   
    }
</style>